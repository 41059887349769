<template>
    <div class="home-menu-mob">
            <nav class="overlayMenu"
                 ref="overlay"
            >
                <div class="logo" v-on:click="navToggle">
                    <router-link :to="{name: 'home', params: { lang: $store.getters.getLang}}">
                        <img src="/dist/images/logo.svg" alt="">
                    </router-link>
                </div>
                <div class="menu-right">
                    <div class="body " ref="navToggle" v-on:click="navToggle">
                        <div class="menu">
                            <router-link :to="{name: 'home', params: { lang: $store.getters.getLang}}" class="flex items-center">{{__('Home')}}</router-link>
                            <router-link :to="{name: 'catalog', params: { lang: $store.getters.getLang}}" class="flex items-center" >{{__('Catalog')}}</router-link>
                            <router-link :to="{ name: 'home', hash: '#services', params: { lang: $store.getters.getLang} }" class="flex items-center" >{{__('Services')}}</router-link>
                            <router-link :to="{name: 'blog', params: { lang: $store.getters.getLang}}" class="flex items-center" href="#">{{__('Blog')}}</router-link>
                            <router-link :to="{ name: 'home', hash: '#about', params: { lang: $store.getters.getLang} }" class="flex items-center" href="#">{{__('About')}}</router-link>
                        </div>
                        <div class="social">
                            <div class="flex flex-row block-men" style="gap:10px">
                                <a target="_blank" class="flex items-center social-link" style="color:inherit;padding:8px;background-color:var(--bgGreyColor);font-weight:700" :href="__('social_links.telegram')">
                                    <svg class="icon icon-tabler icons-tabler-outline icon-tabler-brand-telegram" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" alt="Telegram">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4"></path>
                                    </svg>
                                </a>
                                <a target="_blank" class="flex items-center social-link" style="color:inherit;padding:8px;background-color:var(--bgGreyColor);font-weight:700" href="https://wa.me/+48797443821">
                                    <svg class="icon icon-tabler icons-tabler-outline icon-tabler-brand-whatsapp" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" alt="Whats&#39;App">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9"></path>
                                        <path d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1"></path>
                                    </svg>
                                </a>
                                <a target="_blank" class="flex items-center social-link" style="color:inherit;padding:8px;background-color:var(--bgGreyColor);font-weight:700" href="https://www.instagram.com/aft.broker/">
                                    <svg class="icon icon-tabler icons-tabler-outline icon-tabler-brand-instagram" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" alt="Instagram">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z"></path>
                                        <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                                        <path d="M16.5 7.5l0 .01"></path>
                                    </svg>
                                </a>
                                <a target="_blank" class="flex items-center social-link" style="color:inherit;padding:8px;background-color:var(--bgGreyColor);font-weight:700" href="https://www.facebook.com/AFT.broker/">
                                    <svg class="icon icon-tabler icons-tabler-outline icon-tabler-brand-facebook" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" alt="Facebook">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <DropdownLanguages></DropdownLanguages>
                    </div>
                </div>
            </nav>
        <div class="navBurger" role="navigation" ref="navToggle" v-on:click="navToggle"></div>
    </div>
</template>

<script>
    import DropdownLanguages from "./DropdownLanguages";
    export default {
        name: 'NavMobileHome',
        data: function() {
            return {
            }
        },
        props: {

        },
        methods: {
            navToggle: function () {
                this.$refs.navToggle.classList.toggle('active');
                this.$refs.overlay.classList.toggle('open');
            }
        },
        components: {
            DropdownLanguages
        }
    }
</script>
<style scoped>

    .social{
        list-style: none;
        padding-left: 0;
        margin-top: 30px;
        margin-bottom: 30px;
        text-align: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
    }
    .social li{
        display: inline-block;
        margin-right: 15px;
        margin-left: 15px;
    }
     .social li a{
        display: block;
        width: 30px;
        height: 30px;
        background: rgba(102, 102, 102, 0.5);
        text-align: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
    }


    .overlayMenu .body{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin-top: 20px;
    }
    .overlayMenu .body a{
        margin-top: 20px;
        color: #12204D;
    }
    .overlayMenu .body a.router-link-exact-active{
        color: #BA974D;
    }
    .overlayMenu .body a svg{
    }
    .overlayMenu .logo{
        text-align: center;
        margin-top: 40px;
    }
    .overlayMenu .logo a{

        padding: 0;
        position: relative;
    }
    .overlayMenu .logo a:after{
        display: none!important;
    }
    .overlayMenu .logo a img{
        width: 260px;
    }

    .home-menu-mob{
        display: none;
    }
    .navBurger {
        display: none;
        position: fixed;
        left: 25px;
        top: 47px;
        cursor: pointer;
        background: #12204D;
        box-sizing: border-box;
        background-clip: content-box;
        width: 24px;
        padding-left: 0px;
        padding-right: 0px;
        height: 28px;
        border-top: 12px solid transparent;
        border-bottom: 13px solid transparent;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out;
        z-index: 111;
    }
    .navBurger:before, .navBurger:after {
        content: "";
        position: absolute;
        background: #12204D;
        height: 3px;
        width: 24px;
        left: 0px;
        will-change: rotate;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }
    .navBurger:before {
        top: -8px;
    }
    .navBurger:after {
        bottom: -8px;
    }
    .navBurger.active {
        margin-top: 0px;
        left: 25px;
        position: fixed;
        z-index: 1111;
        background: transparent;
        background-clip: content-box;
    }
    .navBurger.active:before {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        top: 0px;
        background: #12204D;
        width: 100%;
    }
    .navBurger.active:after {
        bottom: 0px;
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
        background: #12204D;
        width: 100%;
    }
    .overlayMenu {
        position: fixed;
        top: 0px;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transform-origin: left top;
        transform: scale(0);
        visibility: hidden;
        transition: all 0.4s ease-in-out;
        z-index: 1111;
        background: #fff;
    }
    .overlayMenu.open {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
        z-index: 1111;
        position: fixed;
    }
    .overlayMenu .menu{
        margin-top: 10px;
        text-align: center;
    }
    .overlayMenu .menu a{
        display: block;
        color: #12204D;
        font-size: 14px;
        margin-bottom: 0px;
        margin-top: 30px;
        -webkit-transition: 300ms;
        transition: 300ms;
    }
    .overlayMenu .menu-right a.router-link-exact-active{
        color: #BA974D;
    }
    .overlayMenu .menu-right a:hover{
        color: #BA974D;
    }
    .overlayMenu .menu-right .v-btn--active:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -6px;
        background-color: #3C55CF;
        display: block;
    }
    .overlayMenu a.router-link-exact-active.active::after{
        bottom: -4px;
    }
    .overlayMenu .menu .v-btn--active{
        color: #3C55CF;
    }


    @media only screen and (max-width : 1200px){
        .navbar.is-visible .navBurger.active {
            margin-top: 0px;
            left: 25px;
            position: fixed;
            z-index: 1111;
            background: transparent;
            background-clip: content-box;
        }
        .home-menu-mob{
            display: block;
            /*position: fixed;*/
            /*top: 0px;*/
            /*width: 100%;*/
            /*left: 0;*/
            /*z-index: 2;*/
            /*background-color: #2C2C2C;*/
            /*height: 100vh;*/
        }
        .navBurger{
            display: block;
        }
        .navbar.is-visible .navBurger{
            position: fixed;
            left: 25px;
            top: 30px;
            cursor: pointer;
            background: #2a2d38;
            box-sizing: border-box;
            background-clip: content-box;
            width: 20px;
            padding-left: 0px;
            padding-right: 0px;
            height: 15px;
            border-top: 6px solid transparent;
            border-bottom: 7px solid transparent;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: all 0.25s ease-in-out;
            transition: all 0.25s ease-in-out;
            z-index: 1111;
        }
        .navbar.is-visible  .navBurger:before, .navbar.is-visible  .navBurger:after {
            background: #2a2d38;
        }
    }
    @media only screen and (max-width : 650px){
        .navBurger{
            top: 26px;
        }
    }
    @media only screen and (max-width : 450px){
        .navBurger {
            left: 20px;
        }
        .navBurger.active{
            left: 20px;
        }
    }
</style>
