<template>
  <div class="content-notFound">
      <div class="content container pc text-wrapper ">
        <div class="left">
           <img src="/dist/images/icon-404.svg" alt="">
           <p>{{__('we are sorry :( the page you requested cannot be found')}}</p>
           <router-link :to="{name: 'home', params: { lang: $store.getters.getLang}}" class="link-button mainColor"><span>{{__('Home')}}</span></router-link>
        </div>  
      </div>
  </div>
</template>

<script>

    export default {
        name: 'NotFound',
        data () {
            return {

            }
        },
        computed: {

        },
        asyncComputed: {

        },
        methods: {
        },
        components: {

        },

    }
</script>
