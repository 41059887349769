export default {
    state: {
        auth: null,
        seo: JSON.parse(window.seo),
    },
    getters: {
        getSeo(state){
            return state.seo;
        },
    },
    actions: {
        asyncAuth(context, payload) {
            context.commit('updateAuth', payload);
        },
    },
    mutations: {
        updateAuth(state, data) {
            this.state.preference.auth= data;
        },
    }
}
