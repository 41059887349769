<template>
      <form class="form-container flex flex-col home-form">
        <div class="inputs-container flex flex-wrap ">
          <div class="input-container flex flex-col">
            <label>{{__('First name')}}</label>
            <InputLabelTextDefault :placeholder="__('John')" class="left-input" type="text" v-model="form.first_name" v-bind:errors="formValidate.first_name" @change="updateValidate('first_name')"></InputLabelTextDefault>
          </div>
          <div class="input-container flex flex-col">
            <label>{{__('Last name')}}</label>
            <InputLabelTextDefault :placeholder="__('Doe')" class="left-input" type="text" v-model="form.last_name" v-bind:errors="formValidate.last_name" @change="updateValidate('last_name')"></InputLabelTextDefault>
          </div>
          <div class="input-container flex flex-col">
            <label>{{__('Email')}}</label>
            <InputLabelTextDefault :placeholder="__('example@mail.com')" class="right-input"  type="email" v-model="form.email" v-bind:errors="formValidate.email" @change="updateValidate('email')"></InputLabelTextDefault>
          </div>
          <div class="input-container flex flex-col">
            <label>{{__('Phone number')}}</label>
            <VuePhoneNumberInput
                    v-model="form.phone"
                    v-bind:error="errorNumber"
                    v-bind:default-country-code="form.phone_country"
                    @update="updateNumber"
            ></VuePhoneNumberInput>
<!--            <InputLabelTextDefault :placeholder="__('__ (___) ___ ___ ___')" class="left-input" type="text" v-model="form.phone" v-bind:errors="formValidate.phone" @change="updateValidate('phone')"></InputLabelTextDefault>-->
          </div>
          <div class="input-container flex flex-col">
            <label>{{__('Message')}}</label>
            <InputLabelTextDefault :placeholder="__('I want to...')" type="textarea" v-model="form.message" v-bind:errors="formValidate.message" @change="updateValidate('message')"></InputLabelTextDefault>
          </div>
        </div>
        <div class="block-bbb">
          <v-btn class="link-button flex-row justify-between items-center mainColor" style="gap:10px" :loading="submitDisabled" v-on:click="openCaptcha">
            {{__('Send a request')}}
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 11L6.5 6L1.5 1" stroke="#fff" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </v-btn>
          <div class="small-font flex flex-row" style="font-weight:300">
            <span class="inherit-span" >{{__('By clicking on the button, you accept the')}}
              <router-link :to="{ name: 'pages', params: { lang, page: 'privacy' } }"  style="font-size:inherit;font-weight:inherit;color:#225DF4" >{{__('privacy policy')}}</router-link>
            </span>
          </div>
        </div>
        <Captcha v-if="captcha" @onSubmit="onSubmit"></Captcha>

        <ModalDefault v-if="dialogText" v-bind:modal_cancel.sync="dialogText" width="680">
          <div class="window-text">
            <div class="img">
              <img src="/dist/images/img-window.svg" alt="">
            </div>
            <div class="block-text">
              <p class="text">{{__('Thank you for your interest!')}}</p>
              <p class="desc">{{__('Your contact information has been successfully sent. We will contact you shortly to discuss details')}}</p>
            </div>
            <div class="block-social" style="gap:10px">
              <a target="_blank" class="flex items-center social-link" style="color:inherit;padding:8px;background-color:var(--bgGreyColor);font-weight:700" :href="__('social_links.telegram')">
                <svg class="icon icon-tabler icons-tabler-outline icon-tabler-brand-telegram" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" alt="Telegram">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4"></path>
                </svg>
              </a>
              <a target="_blank" class="flex items-center social-link" style="color:inherit;padding:8px;background-color:var(--bgGreyColor);font-weight:700" href="https://wa.me/+48797443821">
                <svg class="icon icon-tabler icons-tabler-outline icon-tabler-brand-whatsapp" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" alt="Whats&#39;App">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9"></path>
                  <path d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1"></path>
                </svg>
              </a>
              <a target="_blank" class="flex items-center social-link" style="color:inherit;padding:8px;background-color:var(--bgGreyColor);font-weight:700" href="https://www.instagram.com/aft.broker/">
                <svg class="icon icon-tabler icons-tabler-outline icon-tabler-brand-instagram" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" alt="Instagram">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z"></path>
                  <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                  <path d="M16.5 7.5l0 .01"></path>
                </svg>
              </a>
              <a target="_blank" class="flex items-center social-link" style="color:inherit;padding:8px;background-color:var(--bgGreyColor);font-weight:700" href="https://www.facebook.com/AFT.broker/">
                <svg class="icon icon-tabler icons-tabler-outline icon-tabler-brand-facebook" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" alt="Facebook">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path>
                </svg>
              </a>
            </div>
          </div>
        </ModalDefault>
      </form>
</template>

<script>

  import Captcha from "../modules/Captcha";
  import VuePhoneNumberInput from 'vue-phone-number-input';
  import 'vue-phone-number-input/dist/vue-phone-number-input.css';
  import InputLabelTextDefault from "../modules/InputLabelTextDefault";
  // import DropdownDefault from "../modules/DropdownDefault";
import {maxLength, minLength, required, email} from "vuelidate/lib/validators";
  import ModalDefault from "./ModalDefault";

  export default {
    data () {
      return {
        dialogText: false,
        form: {
          first_name: null,
          last_name: null,
          email: null,
          message: null,
          phone_number: null,
          phone_country: (this.langCode) ? this.langCode.toUpperCase() : null,
          phone_code: null,
        },
        phone: null,
        phone_number: null,
        errorNumber: null,
        formValidate: {
          first_name: null,
          last_name: null,
          email: null,
          message: null
        },
        submitDisabled: false,
        captcha: false,
      }
    },
    watch: {
        langCode(code) {
            this.form.phone_country= code.toUpperCase();
        }
    },
    created() {
        this.form.phone_country= (this.langCode) ? this.langCode.toUpperCase() : null;
    },
    validations() {
      return {
        form: {
          first_name: {
            required,
            maxLength: maxLength(50),
            minLength: minLength(2),
          },
          last_name: {
            // required,
            maxLength: maxLength(50),
            minLength: minLength(2),
          },
          email: {
            email,
            required
          },
          message: {
            required,
          },
        }
      }
    },
    methods: {
        updateNumber: function (event) {
            this.errorNumber= false;
            this.phone_number= event;
        },
      updateValidate: function(type) {
        this.formValidate= this.$changeValidate(this.formValidate, this.$v.form[type], type);
      },
      openCaptcha() {
        let valid= this.$v.form;
        if(valid.$invalid) {
          let massages= {
            'first_name': [],
            'last_name': [],
            'email': [],
            'message': [],
          };
          this.formValidate= this.$formValidate(massages, valid);
        } else {
          this.captcha= true;
        }
      },
      onSubmit: function (session, arrayData) {
        let valid= this.$v.form;
        if(valid.$invalid) {
          let massages= {
            'first_name': [],
            'last_name': [],
            'email': [],
            'message': [],
          };
          this.formValidate= this.$formValidate(massages, valid);
        } else {
          this.submitDisabled = true;

          let response = this.$cleanObject(this.form);
          response['challenge']= session;
          response[session]= arrayData;

          if(this.phone_number.isValid) {
              response['phone']= this.phone_number.countryCallingCode+this.phone_number.nationalNumber;
              response['phone_number']= this.form.phone_number;
              response['phone_country']= this.form.phone_country;
              response['phone_code']= this.form.phone_code;
          }

          this.$http.post('ajax/message', response)
                  .then(response => {
                    return response.json()
                  })
                  .then((response) => {
                    this.$store.dispatch('asyncResp', response.resp_tickets);
                    this.form= {
                        first_name: null,
                        last_name: null,
                        email: null,
                        message: null,
                        phone_number: null,
                        phone_country: (this.langCode) ? this.langCode.toUpperCase() : null,
                        phone_code: null,
                    };
                    this.phone= null;
                    this.phone_number= null;
                    this.errorNumber= null;


                    this.captcha= false;
                    this.dialogText= true;
                    this.submitDisabled= false;
                  }).catch((err) => {
            this.captcha= false;
            this.submitDisabled= false;
            let response= this.httpHandler(err);

            if(response.error) {
              this.formValidate = response.error.message;
            }
          });
        }
      },
    },
      computed: {
        lang() {
          return this.$store.state.language.lang;
        },
          langCode() {
              return this.$store.state.language.code;
          },
      },
      components: {
        ModalDefault,
        VuePhoneNumberInput,
        // DropdownDefault,
        InputLabelTextDefault,
        Captcha
      }
  }
</script>

<style>

</style>
