<template>
    <div class="supp-form">
      <form>
        <div class="form-b">
          <div class="top-form">
            <div class="block">
              <p class="semi text">{{__('Name')}}</p>
              <InputLabelTextDefault  class="left-input" type="text" v-model="form.first_name" v-bind:errors="formValidate.first_name" @change="updateValidate('first_name')"></InputLabelTextDefault>
            </div>
            <div class="block">
              <p class="semi text">{{__('Phone')}}</p>
              <VuePhoneNumberInput
                      v-model="form.phone"
                      v-bind:error="errorNumber"
                      v-bind:default-country-code="form.phone_country"
                      @update="updateNumber"
              ></VuePhoneNumberInput>
            </div>
            <div class="block">
              <p class="semi text">{{__('Email')}}</p>
              <InputLabelTextDefault type="email" v-model="form.email"></InputLabelTextDefault>
            </div>
          </div>

<!--          <div class="form-right">-->
<!--            <div class="block">-->
<!--              <p class="semi text">{{__('Budget')}}</p>-->
<!--              <DropdownDefault :items="selectPrice" v-model="form.price"></DropdownDefault>-->
<!--            </div>-->
<!--            <div class="block">-->
<!--              <p class="semi text">{{__('Investment goals')}}</p>-->
<!--              <DropdownDefault :items="['Relocation', 'Rent', 'Non-resale', 'House by the sea']" v-model="form.investment"></DropdownDefault>-->
<!--            </div>-->
<!--            <div class="block">-->
<!--              <p class="semi text">{{__('Deadline')}}</p>-->
<!--              <DropdownDefault :items="deadline" v-model="form.deadline"></DropdownDefault>-->
<!--            </div>-->
<!--          </div>-->
        </div>

        <div class="btn-form">
          <v-btn class="link-button mainColor" :loading="submitDisabled" v-on:click="openCaptcha">
              <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7383 0.674128C14.12 0.287553 14.7448 0.289937 15.1236 0.679414C15.4915 1.05765 15.4895 1.66058 15.1192 2.03638L5.32964 11.97L0.450149 7.0025C0.080724 6.62642 0.0792272 6.02416 0.446778 5.64624C0.825943 5.25638 1.45146 5.25429 1.83322 5.64161L5.32964 9.18896L13.7383 0.674128Z" fill="white"/>
              </svg>
            {{__('To get a consultation')}}
          </v-btn>
        </div>
        <div class="block-social" style="gap:10px">
          <p class="tt">{{__('Leave a request and our manager will contact you with ready-made solutions for your request')}}</p>
          <div class="href">
            <a target="_blank" class="flex items-center social-link" style="color:inherit;padding:8px;background-color:var(--bgGreyColor);font-weight:700" :href="__('social_links.telegram')">
              <svg class="icon icon-tabler icons-tabler-outline icon-tabler-brand-telegram" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" alt="Telegram">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4"></path>
              </svg>
            </a>
            <a target="_blank" class="flex items-center social-link" style="color:inherit;padding:8px;background-color:var(--bgGreyColor);font-weight:700" href="https://wa.me/+48797443821">
              <svg class="icon icon-tabler icons-tabler-outline icon-tabler-brand-whatsapp" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" alt="Whats&#39;App">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9"></path>
                <path d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1"></path>
              </svg>
            </a>
            <a target="_blank" class="flex items-center social-link" style="color:inherit;padding:8px;background-color:var(--bgGreyColor);font-weight:700" href="https://www.instagram.com/aft.broker/">
              <svg class="icon icon-tabler icons-tabler-outline icon-tabler-brand-instagram" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" alt="Instagram">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z"></path>
                <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                <path d="M16.5 7.5l0 .01"></path>
              </svg>
            </a>
            <a target="_blank" class="flex items-center social-link" style="color:inherit;padding:8px;background-color:var(--bgGreyColor);font-weight:700" href="https://www.facebook.com/AFT.broker/">
              <svg class="icon icon-tabler icons-tabler-outline icon-tabler-brand-facebook" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" alt="Facebook">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path>
              </svg>
            </a>
          </div>

        </div>
      </form>
      <Captcha v-if="captcha" @onSubmit="onSubmit"></Captcha>



    </div>
</template>

<script>
  import VuePhoneNumberInput from 'vue-phone-number-input';
  import 'vue-phone-number-input/dist/vue-phone-number-input.css';
  import Captcha from "../modules/Captcha";
  import InputLabelTextDefault from "../modules/InputLabelTextDefault";
  // import DropdownDefault from "../modules/DropdownDefault";
  // import DropdownDefault from "../modules/DropdownDefault";
  import {maxLength, minLength, required} from "vuelidate/lib/validators";

  export default {
      data () {
          return {

            dialogText: false,
            form: {
                first_name: null,
                phone_number: null,
                phone_country: (this.langCode) ? this.langCode.toUpperCase() : null,
                phone_code: null,
                country: null,
                email: null,
                // price: this.selectPrice[0],
                // deadline: this.deadline[0],
                // investment: 'Relocation'
            },
            formValidate: {
              first_name: null,
            },
            phone: null,
            phone_number: null,
            errorNumber: null,
            submitDisabled: false,
            captcha: false,
          }
      },
    props: {
        modal_cancel: {
            type: [Boolean, Object, Array, String, Number]
        },
      countries: {
        type: Array,
        required: true
      },
      // selectPrice: {
      //   type: Array,
      //   required: true
      // },
      deadline: {
        type: Array,
        required: true
      }
    },
      watch: {
          langCode(code) {
              this.form.phone_country= code.toUpperCase();
          }
      },
    validations() {
      return {
        form: {
          first_name: {
              required,
              maxLength: maxLength(50),
              minLength: minLength(2),
          },

          phone: {
              required,
              maxLength: maxLength(50),
              minLength: minLength(2),
          },

        }
      }
    },
    created() {
        this.form.phone_country= (this.langCode) ? this.langCode.toUpperCase() : null;
    },
    methods: {
      updateNumber: function (event) {
          this.errorNumber= false;
          this.phone_number= event;
      },
      updateValidate: function(type) {
        this.formValidate= this.$changeValidate(this.formValidate, this.$v.form[type], type);
      },
      openCaptcha() {
        let valid= this.$v.form;
        if(valid.$invalid) {
          let massages= {
            'first_name': [],
            'phone': [],
          };
          this.formValidate= this.$formValidate(massages, valid);

          if(!this.phone_number || this.phone_number.nationalNumber && !this.phone_number.isValid) {
              this.errorNumber= true;
          }
        } else {
          this.captcha= true;
        }
      },
      modalClose: function () {
          this.$emit('modalCancel', this.modal_cancel);
          this.$emit('update:modal_cancel', false);
      },
      onSubmit: function (session, arrayData) {
        let valid = this.$v.form;
        if (valid.$invalid && (!this.phone_number || !this.phone_number.isValid)) {
          let massages = {
            'first_name': [],
          };

          if (!this.phone_number || (this.phone_number.nationalNumber && !this.phone_number.isValid)) {
            this.errorNumber = true;
          }
          this.formValidate = this.$formValidate(massages, valid);

        } else {
          this.submitDisabled = true;

          let response = this.$cleanObject(this.form);
          if (this.phone_number && this.phone_number.isValid) {
            response['phone'] = this.phone_number.countryCallingCode + this.phone_number.nationalNumber;
            response['phone_number'] = this.form.phone_number;
            response['phone_country'] = this.form.phone_country;
            response['phone_code'] = this.form.phone_code;
          }

          if (this.form.email) {
            response['email'] = this.form.email;
          }

          response['challenge'] = session;
          response['object_id'] = this.$route.params.id;
          response[session] = arrayData;

          this.$http.post('ajax/message_banner', response)
                  .then(response => response.json())
                  .then((response) => {
                    this.$store.dispatch('asyncResp', response.message);
                    this.form = {
                      first_name: null,
                      phone_number: null,
                      phone_country: (this.langCode) ? this.langCode.toUpperCase() : null,
                      phone_code: null,
                      email: null,  // Очищаем поле email после успешной отправки
                    };
                    this.phone = null;
                    this.phone_number = null;
                    this.errorNumber = null;

                    this.country = false;
                    this.price = false;
                    this.captcha = false;
                    this.dialogText = true;
                    this.submitDisabled = false;
                  }).catch((err) => {
            this.captcha = false;
            this.submitDisabled = false;
            let response = this.httpHandler(err);

            if (response.error) {
              this.formValidate = response.error.message;
            }
          });
        }
      }
    },
      computed: {
          langCode() {
              return this.$store.state.language.code;
          },
      },
      components: {
        // DropdownDefault,
        VuePhoneNumberInput,
        InputLabelTextDefault,
        Captcha
      }
  }
</script>

<style>

</style>
