<template>
    <div class="item-page" v-if="object">
        <vue-headful
                :title="pageTitle + ' | Amphitrite'"
                :description="pageDescription + ' | Amphitrite'"
        />
        <div class="container pc">
            <div class="content-block">
                <div class="left-block">
                    <div class="top-left">
                        <div>
                            <router-link :to="{ name: 'catalog', params: { lang: $store.getters.getLang} }" class="back link-button flex-row justify-between items-center mainColor">
                                <span>
                                    <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.28564 1.5L1.28564 6.5L6.28564 11.5" stroke="#12204D" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    {{__('Return to catalog')}}
                                </span>
                            </router-link>
                            <p class="name">{{ object.name }}</p>
                        </div>
                    </div>
                    <div class="block-img" v-if="object.images.length">
                        <div class="img-0" v-on:click="dialog= true" :style="{ backgroundImage: `url(/${object.images[0].path})` }"></div>
                        <div class="block" v-if="object.images.length">
                            <div v-on:click="dialog= true" v-for="(image, index) in object.images.slice(1, 4)" :key="index" :class="`img-${index + 1} img`" :style="{ backgroundImage: `url(/${image.path})` }"></div>
                            <div class="img-4 img" :style="{ backgroundImage: `url(/${object.images[3].path})` }" v-if="object.images[3]">
                                <a v-on:click="dialog= true">
                                    <p>
                                        <img src="/dist/images/icon-photo.svg" alt="">
                                        <span>{{__('See all Photos')}}</span>
                                    </p>
                                </a>
                            </div>
                        </div>
                        <div v-else></div>
                    </div>
                    <div class="block-img" v-else>
                        <div class="img-0" :style="{ backgroundImage: 'url(/dist/images/default-img.svg)' }" ></div>
                    </div>

                    <div class="details">
                        <div class="detail-text">
                            <div class="block">
                                <div class="top">
                                    <p class="name">{{ object.name }}</p>
                                    <p class="address">
                                        <img src="/dist/images/icon-location.svg" alt="">
                                        <span>{{__('Address')}}:</span>
                                        {{ object.address }}
                                    </p>
                                </div>
                                <div class="price">
                                    <p>$ {{ object.min_price.toLocaleString() }} <span class="p" v-if="object.max_price && object.max_price > 0">- $ {{ object.max_price.toLocaleString() }}</span> <span>{{__('Price')}}</span></p>
                                </div>
                                <div class="icon-block">
                                    <p v-if="object.quarter != null && object.quarter !== '' && object.years != null && object.years !== ''">
                                        <img src="/dist/images/icon-home.svg" alt="">
                                        <span>{{__('Deadline:')}}</span>
                                        Q{{ object.quarter }} {{ object.years }}
                                    </p>
<!--                                    <p>-->
<!--                                        <img src="/dist/images/icon-location.svg" alt="">-->
<!--                                        <span>Area:</span>-->
<!--                                        {{ object.area }}-->
<!--                                    </p>-->
                                    <p v-if="object.floors != null && object.floors !== ''">
                                        <img src="/dist/images/icon-floors.svg" alt="">
                                        <span>{{__('Floors:')}}</span>
                                        {{ object.floors }}
                                    </p>
                                </div>
                            </div>
                            <div class="block-map">
                                <a class="link-button flex-row justify-between items-center mainColor" @click="dialogMap = true">
                                    <span>
                                        <img src="/dist/images/icon-map.svg" alt="">
                                        {{__('View on map')}}
                                    </span>
                                </a>
                            </div>

                        </div>
                        <div class="text">
                            <div v-html="getTextDescription()"></div>
                        </div>
                        <div class="plans" v-if="object.category && object.category.length">
                            <span>{{__('Plan:')}}</span>
                            <div>
                                <p v-for="category in object.category" :key="category.id">
                                    {{ category[lang] }}
                                </p>
                            </div>
                        </div>
                        <div class="extra plans" v-if="object.extra && object.extra.length">
                            <span>{{__('Extra:')}}</span>
                            <div>
                                <p v-for="extra in object.extra" :key="extra.id">
                                    {{ extra[lang] }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="catalog-objects catalog-objects-unit">
                        <ul>
                            <li v-for="object in object.units" :key="object.id" class="object-card">
                                    <div class="object-image" :style="{ backgroundImage: 'url(/' + object.images[0].path + ')' }" v-if="object.images.length"></div>
                                    <div class="object-image" :style="{ backgroundImage: 'url(/dist/images/default-img.svg)' }" v-else></div>
                                    <div class="object-info">
                                        <div class="name-price">
                                            <p class="price">$ {{ object.price }} <span>{{__('Price')}}</span></p>
                                            <p class="name">{{ object.name }}</p>
                                        </div>
                                    </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="right-block">
                    <FormContact></FormContact>
                    <div class="right-b" @click="dialogForm = true">
                        <VueSlickCarousel v-bind="set" v-if="object.banners">
                            <div class="block" v-for="member in object.banners" :key="member.name">
                                <img loading="lazy" width="400"  decoding="async" data-nimg="1" :src="member.img" style="color: transparent;">
                            </div>
                        </VueSlickCarousel>
                    </div>
                </div>
            </div>
            <div class="item-page-objects">
                <div class="catalog-objects">
                    <p class="text">{{__('More objects')}}</p>
                    <ul>
                        <li v-for="object in object.objectOther" :key="object.id" class="object-card">
                            <router-link :to="{ name: 'item_Page', params: { url: object.url, lang: $store.getters.getLang } }">
                                <div class="object-image" :style="{ backgroundImage: 'url(/' + object.images[0].path + ')' }" v-if="object.images.length"></div>
                                <div class="object-image" :style="{ backgroundImage: 'url(/dist/images/default-img.svg)' }" v-else></div>
                                <div class="object-info">
                                    <div class="name-price">
                                        <p class="name">{{ object.name }}</p>
                                        <p class="price">$ {{ object.min_price.toLocaleString() }} <span class="p" v-if="object.max_price && object.max_price > 0">- $ {{ object.max_price.toLocaleString() }}</span> <span>{{__('Price')}}</span></p>
                                    </div>
                                    <div class="block">
                                        <p><img src="/dist/images/icon-home.svg" alt=""> Q{{ object.quarter }} {{ object.years }}</p>
                                        <p><img src="/dist/images/icon-location.svg" alt="">{{ object.address }}</p>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <ModalDefault v-if="dialogMap" v-bind:modal_cancel.sync="dialogMap" width="800">
            <div class="window-map">
                <p class="name">{{__('Object coordinates:')}} {{object.geometry.lat}}, {{object.geometry.lng}}</p>
                <iframe
                        :src="mapSrc"
                        width="100%"
                        height="700"
                        style="border:0;"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
        </ModalDefault>

        <ModalDefault v-if="dialog" v-bind:modal_cancel.sync="dialog" width="90%" >
            <div class="window-slide">
                <p class="text">{{__('Gallery')}}</p>
                <VueSlickCarousel
                        class="block-img"
                        :dots="true"
                        >
                    <div v-for="(image, index) in object.images" :key="index" class="img" :style="{ backgroundImage: 'url(/' + image.path + ')' }">
                    </div>
                </VueSlickCarousel>
            </div>
        </ModalDefault>

        <ModalDefault v-if="dialogForm" v-bind:modal_cancel.sync="dialogForm" width="690">
            <div class="window-form">
                <p class="name">{{__('Individual selection of real estate')}}</p>
                <FormWindow :countries="object.countries" :selectPrice="object.selectPrice" :deadline="object.deadline"></FormWindow>
            </div>
        </ModalDefault>


    </div>
    <div v-else>
        {{__('loading')}}
    </div>


</template>

<script>
    import vueHeadful from 'vue-headful';
    import VueSlickCarousel from 'vue-slick-carousel';
    import 'vue-slick-carousel/dist/vue-slick-carousel.css';
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
    import ModalDefault from "../modules/ModalDefault";
    import FormContact from "../modules/FormContact";
    import FormWindow from "../modules/FormWindow";

    export default {
        name: 'ItemPage',

        data() {
            return {
                set: {
                    "arrows": false,
                    "dots": false,
                    "infinite": true,
                    "autoplay": true,
                    "autoplaySpeed": 5000,
                    "slidesToShow": 1,
                    "slidesToScroll": 1,
                    "initialSlide": 0,
                },
                dialogForm: false,
                dialog: false,
                dialogMap: false,
                object: null,
            };
        },
        created() {
            this.fetchData(this.$route.params.url);
        },
        beforeRouteUpdate (to, from, next) {
            // обрабатываем изменение параметров маршрута...
            // не забываем вызвать next()
            this.dialog= false;
            this.dialogMap= false;
            this.object= null;
            this.fetchData(to.params.url);
            return next();
        },
        methods: {
            fetchData(url) {
                this.$http.get(`ajax/object/${url}`)
                    .then(response => response.json())
                    .then(response => {
                        console.log('Data received:', response);
                        this.object = response.data.object;
                        this.object.category = response.data.category;
                        this.object.extra = response.data.extra;
                        this.object.objectOther = response.data.objectOther;
                        this.object.banners = response.data.banners;
                        this.object.selectPrice = response.data.selectPrice;
                        this.object.countries = response.data.countries;
                        this.object.deadline = response.data.deadline;
                    })
                    .catch(err => {
                        this.httpHandler(err);
                    });
            },
            getTextDescription() {
                const textObj = this.object.text.find(text => text.lang === this.lang);
                return textObj ? textObj.text : '';
            },
        },
        computed: {
            mapSrc() {
                if (this.object && this.object.geometry) {
                    const { lat, lng } = this.object.geometry;
                    console.log('Map coordinates:', lat, lng); // Логирование координат карты
                    return `https://maps.google.com/maps?q=${lat},${lng}&hl=es;z=14&output=embed`;
                }
                return '';
            },
            lang() {
                return this.$store.state.language.lang;
            },
            pageTitle() {
                const textObj = this.object.text.find(text => text.lang === this.lang);
                return textObj && textObj.title ? textObj.title : this.object.name;
            },
            pageDescription() {
                const textObj = this.object.text.find(text => text.lang === this.lang);
                return textObj && textObj.description ? textObj.description : this.object.name;
            }
        },
        components: {
            FormWindow,
            vueHeadful,
            FormContact,
            ModalDefault,
            VueSlickCarousel
        },
        watch: {
            object(newValue) {
                if (newValue && newValue.geometry) {
                    console.log('Object geometry updated:', newValue.geometry);
                }
            }
        }
    };
</script>

<style scoped>
    /* Добавьте стили по необходимости */
</style>