<template>
  <v-app id="app" >
      <router-view> </router-view>
      <notifications group="message" class="notise" position="bottom right" />
      <ModalCookie ></ModalCookie>
  </v-app>
</template>

<script>
    import ModalCookie from "./components/modules/ModalCookie";
// import SyncLoader from 'vue-spinner/src/SyncLoader.vue'
export default {
  name: 'App',
  data: function() {
      return {
      }
  },
  watch: {

  },
    methods:{
        // handleSCroll () {
        //     let header = document.querySelector(".home-menu");
        //     if (window.scrollY > 80) {
        //         console.log(window.scrollY);
        //         header.classList.add("active");
        //     }
        //     else {
        //         console.log(window.scrollY);
        //         header.classList.remove("active");
        //     }
        // }
    },
  computed: {

  },
  created () {
      // window.addEventListener('scroll', this.handleSCroll);
      this.$http.get('ajax/languages')
          .then(response => {
              return response.json()
          })
          .then(response => {
              response= response.data;
              this.$store.dispatch('asyncLanguages', response);
              this.$store.dispatch('asyncFooter', response);
              this.$store.dispatch('asyncLanguageCode', response);

              if(!localStorage.language) {
                  if(this.$filterIndexObject(response.languages, response.code, 'lang') >= 0) {
                      this.$store.dispatch('asyncLanguage', response.code);
                  }
              }
          }).catch((err) => {
          this.httpHandler(err);
      });


      this.$router.beforeEach((to, from, next) => {
          this.$removeAllListeners(window,'scroll');

          return next();
      });
  },
    destroyed () {
        // window.removeEventListener('scroll', this.handleSCroll);

    },
  components: {
      ModalCookie
  },
}
</script>

<style>

</style>

