let langLocation= window.location.pathname.split('/');
let lang= 'en',
    languages= JSON.parse(window.languages);

if(langLocation[1] && (languages.map(function (e) { return String(e['lang']); }).indexOf(langLocation[1]) >= 0)) {
    lang= langLocation[1];
}


export default {
    state: {
        lang: lang,
        langLink: (lang== 'en') ? null : lang,
        list: languages,
        code: null,
        footer: null
    },
    getters: {
        getLanguage(state){
            return state.lang;
        },
        getLang(state){
            return state.langLink;
        },
        getLangs(state){
            return state.list;
        }
    },
    actions: {
        asyncLanguage(context, payload) {
            context.commit('updateLanguage', payload);
        },
        asyncFooter(context, payload) {
            context.commit('updateFooter', payload);
        },
        asyncLanguages(context, payload) {
            context.commit('updateLanguages', payload.languages);
        },
        asyncLanguageCode(context, payload) {
            console.log('asdsda');
            context.commit('updateLanguageCode', payload);
        },
    },
    mutations: {
        updateFooter(state, data) {
            this.state.language.footer= data.footer;
        },
        updateLanguage(state, language) {
            this.state.language.lang= language;
        },
        updateLanguages(state, data) {
            this.state.language.list= (data.length) ? data : null;
        },
        updateLanguageCode(state, data) {
            this.state.language.code= data.code;
        }
    }
}
