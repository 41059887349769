<template>
  <div class="">
    <div
        class="dropdown"
    >
      <v-select
          :items="items"
          :menu-props="{ bottom: true, offsetY: true, contentClass: classDropdown }"
          solo
          v-bind:value="value"
          v-bind="$attrs"
          v-on="inputListeners"
          :hint="(value_key && text && value) ? `${value[value_key]}, ${value[text]}` : null"
          :item-value="value_key"
          :item-text="text"
          :item-disabled="item_disabled"
          :label="placeholder"
      >

        <template v-slot:selection="{item}" v-if="dropSlot">

          <slot name="selection" v-bind="item"></slot>
        </template>
        <template v-slot:item="{item}" v-if="dropSlot">
          <slot name="item" v-bind="item"></slot>
        </template>
      </v-select>
    </div>

  </div>
</template>

<script>
  export default {
      name: 'DropdownDefault',
      data() {
        return {

        }
      },
      props: {
          value: {
              type: [Number, String, Object],
          },
          dropdown: {
              type: Array
          },
          classDropdown: {
              type: String,
              default: "drop-list"
          },
          items: {
            type: Array,
          },
          text: {
              type: String
          },
          placeholder: {
              type: String,
              default: 'Selected',
          },
          value_key: {
              type: String
          },
          item_disabled: {
              type: [Array, Object, String, Boolean, Function],
              default: 'disabled'
          },
          dropSlot: {
              type: Boolean,
              default: false
          }
      },
      computed: {
          inputListeners: function () {
              var vm = this;
              let valueLength= this.valueLength;

              // `Object.assign` объединяет объекты вместе, чтобы получить новый объект
              return Object.assign(
                  {},
                  // Мы добавляем все слушатели из родителя
                  this.$listeners,
                  // Затем мы можем добавить собственные слушатели или
                  // перезаписать поведение некоторых существующих.
                  {
                      // Это обеспечит, что будет работать v-model на компоненте
                      input: function (value) {
                          if(valueLength) {
                              value= value.replace(",",".");
                              value= ((value.indexOf(".") >= 0) ? (value.substr(0, value.indexOf(".")) + value.substr(value.indexOf("."), Number(valueLength)+1)) : value);
                          }
                          vm.$emit("input", value);
                      },
                  }
              );
          },
      },

  }
</script>
<style>



</style>